.root {
    display: flex;
    padding: 0 30px;
    margin-top: 65px;
}

.firstColumn {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 20%;
}

.secondColumn {
    text-align: left;
    width: 80%;
}

.card {
    display: flex;
    gap: 5%;

    background-color: white;
    width: 100%;

    border-radius: 30px;
    padding: 20px;

    img {
        border-radius: 30px;
        width: 240px;
    }
}

.manager {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 30%;
}

.contact {
    display: flex;
    flex-direction: column;
    width: 65%;
    row-gap: 10px;
}

.links {

    margin-top: auto;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.link {
    text-decoration: none;
}

.btn {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background: #07C2EB;
    border-radius: 15px;

    p {
        color: white;
        font-size: 18px;
        font-weight: 600;
    }

    &-call {
        composes: btn;
        background: #3A3F43;
    }

}

.inputs {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    // margin-top: 30px;

    div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 49%;

        label {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 600;

        }

        input,
        textarea {

            border: 1px solid #B3BAC0;
            border-radius: 15px;
            padding: 10px;
            font-family: Montserrat;
            font-size: 18px;
        }

        input {
            height: 60px;
        }

        textarea {
            height: 100px;
        }
    }
}


.captcha {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    gap: 20px;

    text-align: left;
    width: 340px;
}

.x {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3A3F43;
    width: 40px;
    height: 40px;
    color: white;
    border: none;
    cursor: pointer;
}

@media(min-width: 1201px) {
    .hideDesk {
        display: none;
    }
    .info{
        display: none;
    }
}

@media (max-width: 1200px) {
    .manager{
        display: none;
    }

    .secondColumn {
        width: 100%;
    }

    .info{
        display: flex;
        flex-direction: column;
        margin: 20px 0 20px 0;
    }

    .firstColumn {
        display: none;
    }
    .contact{
        width: 100%;
    }
    .inputs{
        flex-direction: column;
        div{
            width: 100%;
        }
    }
}