.root {
    margin-top: 100px;
    border-top: 1px solid #B3BAC0;
    display: flex;
    padding: 20px;
    height: 300px;
    gap: 4%;
}

.first {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 48%;

    &-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
                width: 50px;
                height: 50px;
            }
        }

        p {
            font-size: 48px;
            font-style: bold;

            &span:nth-child(2) {
                font-style: italic;
            }
        }

    }
}

.founded {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: fit-content;
    border-radius: 30px;
    background: #07C2EB;

    p {
        font-size: 16px;
        color: white;
    }
}

.second {
    width: 48%;
    display: flex;

    div {
        width: 50%;
    }

    div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}

.link {
    text-decoration: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #C9D0D8;

    img {
        max-width: 30px;
        max-height: 30px
    }
}

@media(min-width: 1201px) {
    .hideDesk {
        display: none;
    }

}

@media (max-width: 1200px) {
    .hideMob{
        display: none;
    }
    .root {
        height: fit-content;
        flex-direction: column;
    }

    .first-title {
        flex-direction: column;
        align-items: flex-start;
    }

    .founded {
        width: unset;
    }

    .first {
        width: 100%;
    }

    .second {
        width: 100%;
        margin-top: 20px;
        flex-direction: column;

        div:nth-child(2) {
            justify-content: unset;
            gap: 10px;
            margin-top: 20px;
        }
    }
}