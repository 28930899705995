.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);

    /* Semi-transparent background */
    backdrop-filter: blur(10px);
    /* Apply blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    // opacity: 0;
    transition: opacity 0.3s ease;

    // &-open {
    //     opacity: 1;
    // }
    z-index: 10001;
}

.overlay-content {
    max-width: 80vw;
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow: scroll;
}

@media (max-width: 1200px) {
    .overlay-content {
        max-width: 90vw;
    }
}