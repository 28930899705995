.root {
    display: flex;
    padding: 0 30px;
    margin-top: 65px;
}

.firstColumn {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 20%;
}

.secondColumn {
    text-align: left;
    width: 80%;
}

.container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 35px;
    justify-content: space-between;
}

.card {
    width: 33%;
    // height: 538px;
    border-radius: 30px;
    background: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &-big {
        composes: card;
        width: 63%;
    }

    img {
        border-radius: 30px 30px 0 0;
        // object-fit: contain;
        height: 420px;
        width: auto;
        object-fit: cover;
    }

    &-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        margin-top: auto;
    }
}

.plus {
    border: none;
    cursor: pointer;
    background: rgba(58, 63, 67, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: white;
    transition: all 0.3s;
}

.card:hover {
    .plus {
        background: #07C2EB;
        transition: all 0.3s;
    }
}
.card-popper{
    composes: card;
    width: 60vw;
    &-img{
        margin-right: auto;
        margin-left: auto;
        max-width: 420px;
    }
}
@media(min-width: 1201px) {
    .hideDesk {
        display: none;
    }
}

@media (max-width: 1200px) {
    .firstColumn {
        display: none;
    }

    .secondColumn {
        width: 100%;
    }

    .card {
        width: 100%;
        img{
            height: unset;
        }
    }
    .card-popper{
        width: unset;
        img{
            max-width: 100%;
            height: auto;
        }
    }
}