.root {
    display: flex;
    padding: 0 30px;
    margin-top: 60px;
}

.firstColumn {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 20%;
}

.secondColumn {
    text-align: left;
    width: 80%;
}

.container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    // gap: 20px
    row-gap: 40px;
    justify-content: space-between;
}
// .container :nth-child(4n) {
//     margin-left: auto;
// }

.card {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    width: 31%;
    height: 583px;
    border-radius: 30px;
    background-color: white;
    padding: 30px;
    text-align: left;


    img {
        width: 160px;
        height: 220px;
        border-radius: 15px;
    }
}


.button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    gap: 10px;
    
    min-height: 60px;
    width: 100%;
    height: 60px;
    border-radius: 15px;
    background: rgba(224, 228, 232, 1);
    border: none;
    cursor: pointer;

    &-plus {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;

    }

    &:hover {
        background: rgba(58, 63, 67, 1);

        p {
            color: white;
        }
    }

}

.moreButton {
    composes: button;

    background: rgba(58, 63, 67, 1);

    p {
        color: white;
    }
}

.text{
    opacity: 1;
    color: rgba(46, 50, 53, 1);
    &-title{
        composes: text;
        font-size: 28px;
        font-weight: 600;
    }
    &-sub{
        composes: text;
        font-size: 16px;
    }
    &-description {
        composes: text;
        max-height: 116px;
        overflow: hidden;
    }
}
@media(min-width: 1201px){
    .hideDesk{
        display: none;
    }
}
@media (max-width: 1200px) {
    .firstColumn{
        display: none;
    }
    .hideMob{
        display: none;
    }
    .card{
        width: 100%;
    }
    .secondColumn{
        width: 100%;
    }
}