.root {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;

    position: fixed;
    top: 30px;
    transition: top 0.3s;
    z-index: 1000;

    &-scrolled {
        composes: root;
        top: 0;
        padding-top: 5px;
        padding-bottom: 5px;

        // backdrop-filter: blur(7px);
    }
}

.langContainer {
    display: flex;
    column-gap: 15px;
    align-items: center;
}

.lang {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 40px;
    border-radius: 15px;
    column-gap: 5px;

    background-color: white;

    font-size: 16px;
}

.nav {
    display: flex;
    align-items: center;
    background: rgba(58, 63, 67, 0.4);
    height: 70px;
    border-radius: 15px;
    padding: 0 10px;

    p {
        color: white;
    }

    &-item {
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 212px;
        height: 50px;
        border-radius: 10px;
        color: white;
        column-gap: 5px;

        p:nth-child(1) {
            font-style: italic;
        }

        transition: all 0.1s;

        &-active {
            composes: nav-item;
            background: rgba(7, 194, 235, 1);

            p {
                font-weight: 600;
            }
        }
    }
}

.icon {
    width: 60px;
    height: 60px;
}
.burgerContainer{
    display: none;
}
@media (max-width: 1200px) {
    .root{
        display: none;
    }
    .burgerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100% - 20px);

        position: fixed;
        top: 10px;
        transition: top 0.3s;
        z-index: 1000;

        margin: 0 10px;
    }

    .burger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: transparent;
        cursor: pointer;
        background-color: white;

        padding: 5px;
        border-radius: 6px;
        gap: 3px;
        z-index: 1;

        // margin-right: 20px;
    }
    

    .burgerLine {
        width: 100%;
        height: 1.5px;
        background-color: #333;
    }

    .menu {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .menuNav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 40px 40px 40px;
    }

    .navItem {
        width: 100%;
        padding: 10px;
        text-align: center;
    }
    .lang{
        border-radius: 10px;
        margin-top: 30px;
        border: 1px #C9D0D8 solid;
    }

    .icon{
        width: 40px;
        height: 40px;
    }
}