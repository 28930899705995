.root {
    display: flex;
    flex-direction: column;
    row-gap: 40px;

}
h1{
    color: white;
}
.back {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;
}

.panel {
    margin-top: 55vh;
    margin-left: 40px;

    display: flex;
    column-gap: 20px;
    justify-content: space-between;

    width: 900px;
    height: 280px;



    border-radius: 30px;
    padding: 30px;
    background: rgba(58, 63, 67, 0.4);
    backdrop-filter: blur(7px);

    p {
        color: white;
    }

    &-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        flex-grow: 1;

        &-container {
            display: flex;
            column-gap: 20px;
        }

        font-size: 70px;
        color: white;

        &span:nth-child(2) {
            font-style: italic;
        }

        &-subtitle {
            font-size: 22px;
            font-weight: 500;
            max-width: 60%;
            align-self: flex-end;
            text-align: end;
        }
    }

}

.icon {
    width: 86px;
    height: 86px;
}

.intro {
    display: flex;
    border-radius: 30px;
    background-color: white;
    margin: 0 40px;
    padding: 20px;
    padding-bottom: 80px;
    z-index: 1;
    flex-direction: column;
    position: relative;

    &-columns {
        display: flex;
        justify-content: space-between;
    }

    &-title {
        font-size: 32px;
        font-weight: 500;
        text-align: start;
    }


    &-author {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        align-items: end;
        width: 22%;


        img {
            width: 100%;
            max-width: 400px;
            height: auto;
            border-radius: 15px;
        }

        &-name {
            font-size: 22px;
        }

        &-jobTitle {
            font-size: 16px;
        }
    }
}

.intro-article {
    display: flex;
    column-gap: 20px;
    width: 45%;

    &-text {
        text-align: start;
        font-size: 18px;
    }

}

.intro-article-container {
    display: flex;
    column-gap: 20px;

    img {
        width: 429px;
        height: 583px;
        border-radius: 30px;
    }
}

.snippets {
    display: flex;
    gap: 20px;

    @media (max-width: 1500px) {
        flex-wrap: wrap;
    }
}

.snippet {

    text-align: start;
    margin-bottom: 30px;

    &-title {
        display: flex;
        font-weight: 600;
        font-size: 56px;
        color: #bfc5cd;
        column-gap: 20px;
    }
}

.intro {
    max-height: 650px;
    overflow: hidden;
    // transition: all 0.5s;
    transition: max-height 0.5s linear;


    &-open {
        composes: intro;
        max-height: 10000px;
    }
}

.buttonContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;


    background: rgba(7, 194, 235, 1);

    height: 60px;
    margin: 20px -20px -20px -20px;

    width: 100%;
    position: absolute;
    bottom: 20px;

    &-btn {
        display: flex;
        align-items: center;
        column-gap: 10px;

        div {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }
}

.blur {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;

    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 100%);

}

@media (max-width: 1200px) {
    .hideMob {
        display: none;
    }
    .root {
        row-gap: 30px;
    }
    .intro-article {
        width: 100%;
    }
    .intro{
        margin: 0 20px;
    }

    .intro-columns {
        flex-direction: column;
    }

    .intro-author {
        width: 100%;
    }

    .panel {
        width: calc(100% - 40px);
        height: fit-content;
        flex-direction: column;

        margin-top: 20vh;
        margin-left: 20px;
        margin-right: 20px;

        &-text {
            &-subtitle {
                text-align: left;
                max-width: unset;
                margin-top: 20px;
            }
        }

        .img {
            margin-top: 20px;
        }
    }
    .icon {
        width: 60px;
        height: 60px;
    }

}