*{
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: Montserrat;
  src: url("../public/Montserrat-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url("../public/Montserrat-SemiBold.ttf");
  font-weight: 600;
}
p{
  font-family: Montserrat;
  margin: 0;
  color: rgba(46, 50, 53, 1);
}

h1{
  font-family: Montserrat;
  margin: 0;
  color: rgba(46, 50, 53, 1);
  font-size: 70px;
  font-weight: 600;
  @media(max-width: 1200px){
    font-size: 50px;
  }
}

h2{
    font-family: Montserrat;
    margin: 0;
    color: rgba(46, 50, 53, 1);
    font-size: 64px;
    font-weight: 600;
    @media(max-width: 1200px){
      font-size: 44px;
    }
}

h3{
  font-family: Montserrat;
  margin: 0;
  color: rgba(46, 50, 53, 1);
  font-size: 44px;
  font-weight: 600;
  @media(max-width: 1200px){
    font-size: 32px;
  }
}

h4{
  font-family: Montserrat;
  margin: 0;
  color: rgba(46, 50, 53, 1);
  font-size: 32px;
  font-weight: 500;
  @media(max-width: 1200px){
    font-size: 24px;
  }
}